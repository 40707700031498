// import axios from 'axios'
// import { useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'

import { messagingRecentUsersService, messagingService } from '../services'

import { useRecoilValue } from 'recoil'
import { incomingMessage, activeChatUser } from '../recoil/atoms/chat'

import { Layout } from '../components/layout'
import ChatTab from '../components/chat/ChatTab'
import ChatPerson from '../components/chat/ChatPerson'
import HeadingsH2 from '../components/headings/HeadingsH2'
import InfiniteList, { withGlobalFilter } from '../components/layout/list/InfiniteList'

import { uuidv4 } from "../lib/helpers"
import BlueButton from '@/components/buttons/BlueButton'
import Modal from '@/components/modals/Modal'
import SendMassChatForm from '@/components/forms/SendMassChatForm'

const InfiniteListWithGlobalFilter = withGlobalFilter(InfiniteList)

const Chat = () => {
  const activePersonUserId = useRecoilValue(activeChatUser)
  const socketIncomingMessage = useRecoilValue<any>(incomingMessage)

  const [items, setItems] = useState<any>([])
  const [person, setPerson] = useState<any>(null)
  const [listKey, setListKey] = useState<any>(uuidv4())
  const [sendMassChatDialogOpen, setSendMassChatDialogOpen] = useState(false)

  const activePersonUserIdRef = useRef<any>()

  const selectPerson = (person: any) => {
    setPerson(person)
  }

  useEffect(() => {
    if (socketIncomingMessage.type !== 'message') {
      return
    }
    const userUpdated: any = socketIncomingMessage.from_user

    setItems((items: any) => {
      // if user is not in list - reload component
      if (!items.find((item: any) => item.user_id === userUpdated)) {
        setListKey(uuidv4())
        return items
      } else {
        return items.map((item: any) => {
          if (item.user_id === activePersonUserIdRef.current) {
            return { ...item, unread_count: 0 } 
          } else if(item.user_id === userUpdated) {
            return { ...item, unread_count: 1 }
          } else {
            return item
          }
        })
      }
    })
  }, [socketIncomingMessage])

  useEffect(() => {
    activePersonUserIdRef.current = activePersonUserId
    setItems((items: any) => {
      return items.map((item: any) => {
        return item.user_id === activePersonUserId ? { ...item, unread_count: 0 } : item
      })
    })
  }, [activePersonUserId])

  const sendMassChat = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    messagingService.broadcastMessage(
      values['message'],
      values['recipients']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Broadcasted message')
        setSendMassChatDialogOpen(false)
        setListKey(uuidv4())
        setPerson(null)
      } else {
        console.error(data)
        toast.error('Failed to broadcast message')
      }
      setSubmitting(false)
    }).catch((error) => {
      if (error.code === 400) {
        toast.warning('The max number of recipients allowed is 50')
      } else {
        console.error(error)
        toast.error('Failed to broadcast message')
      }
      setSubmitting(false)
    })
  }

  return (
    <Layout title="Chat" auth={true} back={true}>
      <Modal
        title="Send Mass Chat"
        open={sendMassChatDialogOpen}
        setOpen={setSendMassChatDialogOpen}
        hasForm={true}
      >
        <SendMassChatForm handleSubmit={sendMassChat} />
      </Modal>
      <HeadingsH2 heading="Chat" />
      <BlueButton
        title="Send Mass Chat"
        onClick={() => setSendMassChatDialogOpen(true)}
        className="mt-4"
      />
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 mt-4">
        <div className="w-full sm:w-1/3 flex-initial mr-5 border-b-2 border-teal-600 sm:border-none">
          <InfiniteListWithGlobalFilter
            key={listKey}
            service={messagingRecentUsersService}
            onServiceError={toast.error}
            itemComponent={ChatPerson}
            itemsProps={{ onCellClick: selectPerson, activePersonUserId }}
            noResultsText={`No Recent Chats`}
            parentItems={items}
            setParentItems={setItems}
          />
        </div>
        <div className="bg-white flex-auto px-3 sm:rounded-md bg-gray-100 sm:bg-white">
          {person && <ChatTab person={person} showHeader={true} />}
        </div>
      </div>
    </Layout>
  )
}

export default Chat
