import { IJob } from '@/services/jobs.service'
import { Link } from 'react-router-dom'
import { valueOrNA } from '../../utils'

const JobItemInner = ({ job }: { job: IJob }) => {
  return (
    <li className='flex-1 w-48 col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 border-2 border-transparent hover:border-sky-800 hover:border-4'>
      <div className="w-full flex-row text-center items-center justify-between p-6">
        <div className="flex-1">
          <h3 className="text-teal-900 text-sm font-semibold truncate">
            {job.published_version?.title}
          </h3>
          <h3 className="text-teal-900 text-sm font-semibold truncate">
            <small>{' '}at{' '}{job.company?.name}</small>
          </h3>
        </div>
        <div className="flex-1">
          <p className="mt-1 text-gray-500 text-sm truncate">{valueOrNA(job.published_version?.work_type?.title)}</p>
        </div>
      </div>
    </li>
  )
}

const JobItem = ({ job, link = false, from = null }: any) => {
  const fromLink = from === null ? '/home/' : from
  return (
    <>
      {link ?
        <Link to={{ pathname: `/jobs/${job.id}`, state: { from: fromLink } }}>
          <JobItemInner job={job} />
        </Link>
        :
        <JobItemInner job={job} />
      }
    </>
  )
}

export default JobItem
