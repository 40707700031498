import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { LocationMarkerIcon, BriefcaseIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"
import { commonTables } from "@/recoil/atoms/common"

import { userState } from '@/recoil/atoms/auth'
import {
  commonHelpers,
  jobsService,
  trackingService,
  NoteTables,
  serviceType,
  companyJobsService,
  recruitmentService,
} from '../services'

import { Layout } from '@/components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import { valueOrNA, CollapsableText } from '@/components/utils'
import Modal from '@/components/modals/Modal'
import EmailShareForm from '@/components/jobs/forms/EmailShareForm'
import { IJob } from '@/services/jobs.service'
import SlideOver from '@/components/slideover/SlideOver'
import RecruitForJobForm from '@/components/recruit/RecruitForJobForm'
import JobForm from "@/components/profile/company/jobs/JobForm"
import HeadingsH2 from '@/components/headings/HeadingsH2'
import NotesTab from '@/components/notes/NotesTab'
import AppliedPeople from '@/components/jobs/applied/AppliedPeople'
import BaseTabs from '@/components/layout/navigation/BaseTabs'

import WhiteButton from '@/components/buttons/WhiteButton'
import WhiteButtonLink from '@/components/links/WhiteButtonLink'
import AssociateRecruitingProcessForm from '@/components/jobs/forms/AssociateRecruitingProcessForm'



const JobDetails = ({ job }: { job: IJob }) => {
  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Compensation</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {
              !job.published_version || (!job.published_version.compensation_lower && !job.published_version.compensation_upper)
                ? 'N/A'
                : `${job.published_version.compensation_lower && Number(job.published_version.compensation_lower)}${job.published_version.compensation_upper && `${job.published_version.compensation_lower && ' - '}${job.published_version.compensation_upper && Number(job.published_version.compensation_upper)}`}`
            }
          </dd>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(job.published_version?.compensation_type?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Work Schedule</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(job.published_version?.work_type?.title)}</dd>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(job.published_version?.work_env?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Industry / Role</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(job.published_version?.industry?.title)}</dd>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(job.published_version?.role?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Experience</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(job.published_version?.experience?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Travel Availability</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(job.published_version?.travel_availability?.title)}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-teal-600">Description</dt>
          <dd className="mt-1 text-sm text-gray-900"><CollapsableText text={valueOrNA(job.published_version?.description)} /></dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-teal-600">Responsibilities</dt>
          <dd className="mt-1 text-sm text-gray-900"><CollapsableText text={valueOrNA(job.published_version?.requirements)} /></dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-teal-600">Qualification</dt>
          <dd className="mt-1 text-sm text-gray-900"><CollapsableText text={valueOrNA(job.published_version?.qualification)} /></dd>
        </div>
      </dl>
    </div>
  )
}

const Benefits = ({ job }: { job: IJob }) => {
  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-teal-600">Benefits</dt>
          <dd className="mt-1 text-sm text-gray-900"><CollapsableText text={valueOrNA(job.published_version?.benefits)} /></dd>
        </div>
      </dl>
    </div>
  )
}

const Company = ({ company }: any) => {
  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">{company?.name}</dt>
          <dd className="mt-1 text-sm text-gray-900">{company?.business_area}</dd>
          <dd className="mt-1 text-sm text-gray-900">{company?.description}</dd>
        </div>
      </dl>
    </div>
  )
}

const JobTabs = ({ job, companyAdmin = false }: { job: IJob, companyAdmin: boolean }) => {
  const tabs = [
    { slug: 'details', name: 'Details' },
    { slug: 'benefits', name: 'Benefits' },
    { slug: 'company', name: 'Company' },
    { slug: 'notes', name: 'Notes' }
  ]
  const [activeTab, setActiveTab] = useState('details')

  return (
    <>
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        extraButtonClass={'px-4 sm:px-6 lg:px-8'}
      />
      {activeTab === 'details' && <JobDetails job={job} />}
      {activeTab === 'benefits' && <Benefits job={job} />}
      {activeTab === 'company' && <Company company={job.company} />}
      {activeTab === 'notes' && <NotesTab noteTable={NoteTables.Job} object={job} />}
      {activeTab === 'applied' && <AppliedPeople job={job} jobVersion={job.published_version ?? job.latest_version} />}
    </>
  )
}


const Job = () => {
  let { id } = useParams<{ id: string }>()

  const userData = useRecoilValue(userState)
  const common = useRecoilValue<any>(commonTables)

  const [job, setJob] = useState<IJob | null>(null)
  const [loading, setLoading] = useState(true)
  const [appliedLoading, setAppliedLoading] = useState(false)
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [linkRecruitingProcessModalOpen, setLinkRecruitingProcessModalOpen] = useState(false)
  const [applied, setApplied] = useState(false)
  const [recruitOpen, setRecruitOpen] = useState(false)

  const location = useLocation<any>()
  const history = useHistory()
  const hasBack = (location?.state?.from !== undefined)

  const companyAdmin = (
    userData
    && userData.company
    && userData.company.id === job?.company?.id
  )

  const goBack = () => {
    // we migh later use location?.state?.from - 
    // now we just check if we can go back, if 1st page load, there is no back
    history.goBack()
  }

  const applyAction = (jobId: any) => {
    setAppliedLoading(true)
    jobsService.apply(jobId).then((result: any) => {
      setApplied(true)
      setAppliedLoading(false)
    }).catch((error: any) => {
      console.error(error)
      toast.error(error)
    })
  }

  const loadJobDetails = useCallback(() => {
    const source = axios.CancelToken.source()
    setLoading(true)
    jobsService.detail(parseInt(id), source.token).then((result: serviceType.IServiceResult) => {
      if (result.success) {
        setJob(result.data)
        setApplied(result.data.has_applied)
        setLoading(false)
        trackingService.trackJobView(parseInt(id)).catch((error) => console.error(error))
      }
    }).catch((error: any) => {
      console.error(error)
      setLoading(false)
    })
    return () => {
      source.cancel()
    }
  }, [id, setJob])

  const handleUpdate = (values: any) => {
    if (!job) {
      return
    }
    values = Object.assign({}, values, {
      'industry': commonHelpers.industryIdFromKey(common, values.industry),
      'role': commonHelpers.conditionallyOmitRole(common, values.industry, values.role)
    })
    companyJobsService.update(job.id, job.company?.id, values).then((result: serviceType.IServiceResult) => {
      if (result.success) {
        setIsEditDialogOpen(false)
        loadJobDetails()
      }
    }).catch((error: any) => {
      toast.error(`${error}`)
    })
  }

  const handleShareSubmit = (values: any, result: any) => {
    jobsService.share(parseInt(id), values.email).then((result: serviceType.IServiceResult) => {
      if (result.success) {
        setIsShareDialogOpen(false)
        toast.success(`Job detail was sent to: ${values.email}.`)
      } else {
        toast.error(`Share message failed to send`)
      }
    }).catch((error: any) => {
      console.error(error)
      toast.error(`Share message failed to send`)
    })
  }

  useEffect(() => {
    loadJobDetails()
  }, [loadJobDetails])

  const linkRecruitingProcess = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    recruitmentService.linkRecruiterProcessToJob(
      values['recruiting_process'],
      job!.id,
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully linked recruiting pipeline')
        setLinkRecruitingProcessModalOpen(false)
        loadJobDetails()
      } else {
        console.error(data)
        toast.error('Failed to link the recruiting pipeline')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to link the recruiting pipeline')
      setSubmitting(false)
    })
  }

  return (
    <Layout title="Home" auth={true} back={true}>
      {!loading ?
        <>
          <Modal
            title="Share"
            open={isShareDialogOpen}
            setOpen={setIsShareDialogOpen}
            hasForm={true}
          >
            <EmailShareForm handleSubmit={handleShareSubmit} />
          </Modal>

          {job && <SlideOver
            title={`Recruit for: ${job.published_version?.title}`}
            open={recruitOpen}
            setOpen={setRecruitOpen}
            hasForm={true}
          >
            <RecruitForJobForm
              job={job}
            />
          </SlideOver>}

          {job && <SlideOver
            title={`Edit Job: ${job.published_version?.title}`}
            open={isEditDialogOpen}
            setOpen={setIsEditDialogOpen}
            hasForm={true}
            loading={false}
          >
            <JobForm
              object={job}
              initial={job}
              handleSubmit={handleUpdate}
            />
          </SlideOver>}

          {job && <Modal
            title="Link Recruiting Pipeline"
            open={linkRecruitingProcessModalOpen}
            setOpen={setLinkRecruitingProcessModalOpen}
            hasForm={true}
          >
            <AssociateRecruitingProcessForm handleSubmit={linkRecruitingProcess} />
          </Modal>}

          <div className="bg-white px-4 py-2">
            {hasBack &&
              <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
                <button
                  onClick={goBack}
                  className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                  <span>Back</span>
                </button>
              </nav>
            }

            <div className="flex items-center content-center justify-between pt-4 lg:pt-0 mb-2 space-x-2 sm:space-x-0">
              <div className="truncate space-y-4">
                <HeadingsH2 heading={job?.published_version?.title} className="truncate" />
                <div className="flex items-center text-sm text-gray-500">
                  <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <small>{job?.company?.name} {job?.published_version?.role ? `/${job?.published_version?.role.title}` : ''}</small>
                </div>
                {(job?.published_version?.state || job?.published_version?.city) && <div className="flex items-center text-sm text-gray-500">
                  <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <small>
                    {job?.published_version.state?.title}{(job.published_version.state && job.published_version.city) && ', '}{job.published_version.city?.title}
                  </small>
                </div>}
                
                {companyAdmin &&
                  <div className="mt-3">
                    {((userData?.company && job?.company) && (userData.company?.id === job.company.id)) && <WhiteButtonLink
                      title="Go to Company Job Page"
                      to={`/companies/${job.company.id}/job/${job.id}`}
                    />}
                  </div>
                }
              </div>

              <div className="flex flex-col space-y-2">
                {appliedLoading ?
                  <WhiteButton
                    title="Loading"
                    disabled={true}
                  />
                  :
                  <WhiteButton
                    title={applied ? 'Applied' : 'Apply'}
                    disabled={applied}
                    onClick={() => { applyAction(job?.id) }}
                  />
                }
                {job?.recruiter_process && <WhiteButton
                  title="Recruit"
                  onClick={() => setRecruitOpen(true)}
                />}
                {!job?.recruiter_process && <WhiteButton
                  title="Link Recruiting Pipeline"
                  onClick={() => setLinkRecruitingProcessModalOpen(true)}
                />}
                <WhiteButton
                  title="Share"
                  onClick={() => setIsShareDialogOpen(true)}
                />
              </div>
            </div>

            {job && <JobTabs job={job} companyAdmin={companyAdmin ?? false} />}
          </div>
        </>
        :
        <LoadingInside />
      }
    </Layout>
  )
}

export default Job
