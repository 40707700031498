import { Formik } from 'formik'
import * as Yup from 'yup'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { commonTables } from '../../recoil/atoms/common'
import BlueButton from '../buttons/BlueButton'
import { TextInputField, FileField } from '../forms/fields'

// any validation
const FileSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
})

const FileFields = ({ values, errors, touched, handleChange, handleBlur, common, setFieldValue, setFieldTouched, setFieldError }: any) => {
  useEffect(() => {
    if (values.file) {
      setFieldValue('title', values.file.name)
      setTimeout(() => setFieldTouched('title'), 100)
    }
  }, [values.file, setFieldValue, setFieldTouched])

  const commonFieldProps = { values, errors, touched, handleBlur, handleChange, setFieldValue, setFieldError }
  return (
    <div className="space-y-8 sm:space-y-5">
      <FileField
        fieldName="file"
        label="Select File"
        required={false}
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf,image/*"
        {...commonFieldProps}
      />
      <TextInputField
        fieldName="title"
        label="Title"
        required={true}
        {...commonFieldProps}
      />
    </div>
  )
}

const FileForm = ({ handleSubmit, object, formClasses = '', initial=null}: any) => {
  const common = useRecoilValue<any>(commonTables)

  return (
    <Formik
      initialValues={{
        file: '',
        title: initial?.title || '',
      }}
      validationSchema={FileSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, setFieldError }) => (
        <form onSubmit={handleSubmit} className={formClasses}>
          <FileFields
            {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common, setFieldValue, setFieldTouched, setFieldError }}
          />
          <BlueButton
            disabled={!isValid || !dirty || isSubmitting}
            type="submit"
            title="Save"
            className="mt-5 w-full"
          />
        </form>
      )}
    </Formik>
  )
}

export default FileForm
