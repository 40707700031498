import { useCallback, useEffect, useRef, useState } from 'react'
import update from 'immutability-helper'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ChevronLeftIcon, LockClosedIcon, PlusIcon, XIcon } from '@heroicons/react/solid'

import { Layout } from '@/components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import { RecruitingProcessStep } from '@/components/recruit/manage/RecruitingProcessStep'
import { IRecruitingProcess, IRecruitingStep } from '@/recoil/types'
import { recruitmentService } from '@/services'
import { toast } from 'react-toastify';
import HeadingsH3 from '@/components/headings/HeadingsH3'
import TealButton from '@/components/buttons/TealButton'
import Modal from '@/components/modals/Modal'
import CreateRecruitingStepForm from '@/components/recruit/forms/CreateRecruitingStepForm'
import BlueButton from '@/components/buttons/BlueButton'
import { HorizontalShadowScrollbars } from '@/components/utils'
import Scrollbars from 'react-custom-scrollbars-2'
import EditProcessNameForm from '@/components/recruit/forms/EditProcessNameForm'
import RedButton from '@/components/buttons/RedButton'
import Confirm from '@/components/modals/Confirm'
import SlideOver from '@/components/slideover/SlideOver'

const RecruitingProcess = () => {
  let { company_id, process_id } = useParams<{ company_id: string, process_id: string }>()

  const [process, setProcess] = useState<IRecruitingProcess>()
  const [loading, setLoading] = useState(true)
  const [abstractStepsLoading, setAbstractStepsLoading] = useState(true)
  const [abstractSteps, setAbstractSteps] = useState<IRecruitingStep[]>([])
  const [createNewStepModalOpen, setCreateNewStepModalOpen] = useState(false)
  const [addStepToProcessModalOpen, setAddStepToProcessModalOpen] = useState(false)
  const [editNameModalOpen, setEditNameModalOpen] = useState(false)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [isSavingStepOrder, setIsSavingStepOrder] = useState(false)
  const [abstractStepToAdd, setAbstractStepToAdd] = useState<IRecruitingStep>()
  const scrollbarsRef = useRef<Scrollbars>(null)

  const [steps, setSteps] = useState<IRecruitingStep[]>([])

  const history = useHistory()
  const location = useLocation<any>()

  const hasBack = (location?.state?.from !== undefined)

  const goBack = () => {
    history.goBack()
  }

  const getProcessDetails = useCallback(() => {
    setLoading(true)
    recruitmentService.getProcessDetails(parseInt(process_id), null).then((res) => {
      setProcess(res.data)
      setLoading(false)
    }).catch(() => {
      toast.error("Failed to fetch details")
    })
  }, [process_id])

  useEffect(() => {
    process && setSteps(process.steps)
  }, [process])

  const getAbstractSteps = useCallback(() => {
    setAbstractStepsLoading(true)
    recruitmentService.getAbstractSteps(null).then((res) => {
      setAbstractSteps(res.data)
      setAbstractStepsLoading(false)
    }).catch(() => {
      toast.error("Failed to load steps toolbox")
    })
  }, [])

  useEffect(() => {
    getProcessDetails()
    getAbstractSteps()
  }, [getProcessDetails, getAbstractSteps])

  const moveStep = (dragIndex: number, hoverIndex: number): boolean => {
    setSteps((prevSteps: IRecruitingStep[]) => {
      if (hoverIndex === prevSteps.length - 1) {
        return prevSteps
      }

      return update(prevSteps, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSteps[dragIndex] as IRecruitingStep],
        ],
      })
    })

    return true
  }

  const updateStepOrder = (item: { id: number, index: number, type: string }) => {
    setIsSavingStepOrder(true)
    const prevStepId = item.index <= 1 ? 0 : steps[item.index - 1].id
    const nextStepId = item.index === steps.length - 1 ? undefined : steps[item.index + 1].id
    recruitmentService.updateStepOrder(item.id, prevStepId, nextStepId).then(() => {
      setIsSavingStepOrder(false)
    }).catch(() => {
      toast.error("Failed to update step order")
      setIsSavingStepOrder(false)
      getProcessDetails()
    })
  }

  const createRecruitingStep = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    recruitmentService.createStep(
      values['name'],
      values['description'],
      values['duration'],
      values['involves_candidate'],
      values['color'].replace('#', ''),
      values['is_abstract'],
      values['order'],
      false,
      values['assignees'],
      values['is_abstract'] !== true ? process!.id : undefined,
      parseInt(company_id)
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully created recruiting step')
        setCreateNewStepModalOpen(false)
        setAddStepToProcessModalOpen(false)

        if (data.is_abstract) {
          getAbstractSteps()
        } else {
          getProcessDetails()
        }
      } else {
        console.error(data)
        toast.error('Failed to create recruiting step')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  const addAbstractStepToProcess = (step: IRecruitingStep) => {
    setAbstractStepToAdd(step)
    setAddStepToProcessModalOpen(true)
  }

  const deleteStep = (step: IRecruitingStep) => {
    recruitmentService.deleteStep(step.id).then(() => {
      toast.success("Deleted step")
      getProcessDetails()
    }).catch(() => {
      toast.error("Failed to delete step")
    })
  }

  const deleteAbstractStep = (step: IRecruitingStep) => {
    recruitmentService.deleteStep(step.id).then(() => {
      toast.success("Deleted step")
      getAbstractSteps()
    }).catch(() => {
      toast.error("Failed to delete step")
    })
  }

  const updateProcessName = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    recruitmentService.updateProcessName(process!.id, values['name']).then(({success, data}) => {
      if (success) {
        toast.success('Successfully updated pipeline name')
        setEditNameModalOpen(false)
        setProcess((process?: IRecruitingProcess) => {
          process && (process.name = values['name'])
          return process
        })
      } else {
        console.error(data)
        toast.error('Failed to update pipeline name')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  const deleteProcess = () => {
    recruitmentService.deleteProcess(process!.id).then(() => {
      toast.success(`Successfully deleted pipeline "${process?.name}"`)
      history.push('/company-profile')
    }).catch(() => {
      toast.error("Failed to delete pipeline")
    })
  }

  return (
    <Layout title="Job Pipeline" auth={true} back={true} sidebar={false}>
      <SlideOver
        title="Create New Recruiting Step"
        open={createNewStepModalOpen}
        setOpen={setCreateNewStepModalOpen}
        hasForm={true}
      >
        <CreateRecruitingStepForm handleSubmit={createRecruitingStep} isAbstract={true} companyId={parseInt(company_id)} />
      </SlideOver>
      <SlideOver
        title="Add Recruiting Step to Pipeline"
        open={addStepToProcessModalOpen}
        setOpen={setAddStepToProcessModalOpen}
        hasForm={true}
      >
        {steps.length > 0 && <CreateRecruitingStepForm
          handleSubmit={createRecruitingStep}
          isAbstract={false}
          order={steps[steps.length - 1].order}
          initialStep={abstractStepToAdd}
          companyId={parseInt(company_id)}
        />}
      </SlideOver>
      <Modal
        title="Edit Pipeline Name"
        open={editNameModalOpen}
        setOpen={setEditNameModalOpen}
        hasForm={true}
      >
        {process && <EditProcessNameForm handleSubmit={updateProcessName} name={process.name} />}
      </Modal>
      <Confirm
        title={'Are you sure to delete this pipeline?'}
        onConfirm={() => deleteProcess()}
        isOpen={isDeleteConfirmOpen}
        setIsOpen={setIsDeleteConfirmOpen}
      />
      {!loading ?
        <>
          {hasBack && <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
            <button
              onClick={goBack}
              className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
            >
              <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
              <span>Back</span>
            </button>
          </nav>}

          <div className="mb-2">
            <HeadingsH2 heading={`Recruiting Pipeline: "${process?.name}"`} className="truncate" />
            {process?.is_locked && <div className="mt-2 mb-4 flex">
              <div className="flex items-center text-sm text-gray-500">
                <LockClosedIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <p>Locked</p>
              </div>
            </div>}
          </div>

          {process?.is_locked === false && <div className="flex gap-2 mb-6 ml-4 sm:ml-0">
            <BlueButton
              title="Edit Name"
              onClick={() => setEditNameModalOpen(true)}
            />
            {process.is_abstract === true && <RedButton
              title="Delete Pipeline"
              onClick={() => setIsDeleteConfirmOpen(true)}
            />}
          </div>}

          {process?.is_locked === false && <div className="bg-white shadow p-4 mb-4">
            <HeadingsH3 heading="Steps Toolbox" />
            {abstractStepsLoading ? <LoadingInside /> : <>
              <div className="flex gap-4 mt-2 flex-wrap">
                {abstractSteps.map((step) => (
                  <div className="border border-gray-200 w-60 p-2" key={step.id}>
                    <div className="font-bold text-gray-800" title={step.name}>{step.name}</div>
                    <div className="text-sm text-gray-600 whitespace-normal line-clamp-2" title={step.description}>{step.description}</div>
                    <div className="w-full h-2 mt-1 mb-2" style={{ backgroundColor: `#${step.color ?? '000000'}` }}></div>
                    <div className="flex gap-1">
                      <BlueButton
                        title=""
                        showTitle={false}
                        children={<PlusIcon className="h-4 w-4" aria-hidden="true" />}
                        className="px-1 py-1"
                        onClick={() => addAbstractStepToProcess(step)}
                      />
                      <RedButton
                        title=""
                        showTitle={false}
                        children={<XIcon className="h-4 w-4" aria-hidden="true" />}
                        className="px-1 py-1"
                        onClick={() => deleteAbstractStep(step)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <TealButton
                title="Create New"
                className="mt-4"
                onClick={() => setCreateNewStepModalOpen(true)}
              />
            </>}
          </div>}

          <HeadingsH3 heading="Pipeline Steps" />
          <HorizontalShadowScrollbars
            ref={scrollbarsRef}
            className="overflow-x-auto whitespace-nowrap pt-2"
            style={{ width: '100%', height: '146px' }}
            renderTrackHorizontal={({ style, ...props }) =>
              <div {...props} style={{
                ...style,
                top: 0,
                right: 4,
                left: 4,
                borderRadius: 3,
                zIndex: 10
              }} />
            }
            onWheel={(e) => {
              const wrapper = document.getElementById('infinite-scroll-wrapper')
              if (wrapper && wrapper.scrollHeight <= wrapper.clientHeight) {
                scrollbarsRef.current && scrollbarsRef.current.scrollLeft(scrollbarsRef.current.getScrollLeft() + e.deltaY)
              }
            }}
          >
            <div className="pt-4 space-x-4 px-1">
              {steps.map((step, i) => (
                <RecruitingProcessStep
                  key={step.id}
                  index={i}
                  step={step}
                  moveStep={moveStep}
                  updateStepOrder={updateStepOrder}
                  deleteStep={() => deleteStep(step)}
                  isLoading={isSavingStepOrder}
                  isLocked={(process?.is_locked ?? true) || step.id <= 0}
                  canDelete={step.can_delete && steps.filter((_step) => _step.can_delete).length > 1}
                  companyId={parseInt(company_id)}
                />
              ))}
            </div>
          </HorizontalShadowScrollbars>
        </>
        :
        <LoadingInside />
      }
    </Layout>
  )
}

export default RecruitingProcess
