import { ProfileImage } from '@/components/utils'
import { Link } from 'react-router-dom'

const ConnectionItemInner = ({ person }: any) => {
  return (
    <li className='flex-1 w-48 col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 border-2 border-transparent hover:border-sky-800 hover:border-4'>
      <div className="w-full flex-row text-center items-center justify-between p-6">
        <div className="flex-1">
          <ProfileImage image={person.profile_image} size={10} iconClass={'mx-auto w-12 h-12 rounded-full flex-shrink-0 mb-2'} />
          <h3 className="text-teal-600 text-sm font-semibold truncate">{(person.first_name && person.last_name) ? `${person.first_name} ${person.last_name}` : 'Unknown'}</h3>
        </div>
        <div className="flex-1">
          <p className="mt-1 text-gray-500 text-sm truncate">{person.role || 'N/A'}</p>
        </div>
        {person.status && <div className="flex-1">
          {person.status === 'pending' && <p className="mt-1 text-gray-500 text-sm truncate font-bold italic">Pending...</p>}
        </div>}
      </div>
    </li>
  )
}


const ConnectionItem = ({ person, link = false, from = null }: any) => {
  const fromLink = from === null ? '/home/' : from
  return (
    <>
      {link ?
        <Link to={{ pathname: `/people/${person.id}`, state: { from: fromLink } }}>
          <ConnectionItemInner person={person} />
        </Link>
        :
        <ConnectionItemInner person={person} />
      }
    </>
  )
}

export default ConnectionItem
