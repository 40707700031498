import { Formik } from 'formik'
import * as Yup from 'yup'

import BlueButton from '@/components/buttons/BlueButton'
import { FileField, TextInputField } from '@/components/forms/fields'

const FileSchema = Yup.object().shape({
  file: Yup.string().required('Required'),
  name: Yup.string().required('Required')
})

const FileFields = ({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, setFieldError }: any) => {
  const commonFieldProps = { values, errors, touched, handleBlur, handleChange, setFieldValue, setFieldError }
  return (
    <div className="space-y-8 sm:space-y-5">
      <FileField
        fieldName="file"
        label="Select File"
        required={false}
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf,image/*"
        {...commonFieldProps}
      />
      <TextInputField
        fieldName="name"
        label="Name"
        required={true}
        {...commonFieldProps}
      />
    </div>
  )
}

const RecruitingStepFileForm = ({ handleSubmit, formClasses = '' }: any) => {
  return (
    <Formik
      initialValues={{
        file: '',
        name: '',
      }}
      validationSchema={FileSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, setFieldError }) => (
        <form onSubmit={handleSubmit} className={formClasses}>
          <FileFields
            {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }}
          />
          <BlueButton
            type="submit"
            title={isSubmitting ? "Uploading..." : "Upload"}
            className="mt-5 w-full"
            disabled={!isValid || !dirty || isSubmitting}
          />
        </form>
      )}
    </Formik>
  )
}

export default RecruitingStepFileForm
