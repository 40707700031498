import CompanyForm from "./forms/CompanyForm"
import { valueOrNA } from '../../utils'
import { useState } from "react"
import TealButton from "../../buttons/TealButton"

const CompanyInfo = ({ company, canEdit = false }: any) => {
  const [isEditing, setIsEditing] = useState(false)
  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }
  return (
    <>
      {canEdit && <div className="py-4">
        <TealButton
          title={isEditing ? 'Cancel' : 'Edit'}
          className=""
          onClick={() => { toggleIsEditing() }}
        />
      </div>}
      {isEditing ?
        <CompanyForm toggleIsEditing={toggleIsEditing} company={company} />
        :
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 p-3 sm:grid-cols-2 mt-4">
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.name)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Contact</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.contact)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Industry</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.industry?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">State</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.state?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">City</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.city?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Description</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.name)}</dd>
          </div>
        </div>}
    </>
  )
}

export default CompanyInfo
