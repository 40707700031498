import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { isLoggedIn } from '../../recoil/atoms/auth'
import { accountService } from '../../services'
import { toast } from 'react-toastify'

// Loading
import GoogleLogin from './GoogleLogin'

export default function SocialAuth({redirectToAfterLogin}:any) {
  const setLoggedIn = useSetRecoilState(isLoggedIn)
  let history = useHistory()
  const [fbSubmitting, setFbSubmitting] = React.useState(false)

  const loginWithFacebook = () => {
    setFbSubmitting(true)
    accountService.facebookLogin().then(res => {
      if (res.success) {
        toast.success(`Logged in...`)
        setFbSubmitting(false)
        setLoggedIn(true)
        history.push(redirectToAfterLogin, history.location.state)
      }
    }).catch(err => {
      toast.error(`${err}`)
      setFbSubmitting(false)
    })
  }
  const loginWithGoogle = (googleResponse: any) => {
    accountService.googleLogin(googleResponse).then(res => {
      if (res.success) {
        toast.success(`Logged in...`)
        setLoggedIn(true)
        history.push(redirectToAfterLogin, history.location.state)
      }
    }).catch(err => {
      toast.error(`${err}`)
    })
  }

  return (
    <div className="mt-8">
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">Or continue with</span>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-3">
        <div>
          <button
            onClick={loginWithFacebook}
            className="w-full inline-flex justify-center py-2 px-4 rounded-md shadow-sm bg-teal-600 text-sm font-medium text-gray-500 hover:bg-teal-500"
          >
            <span className="sr-only">
              {fbSubmitting ? "Signing in..." : "Sign in with Facebook"}
            </span>
            <svg className="w-5 h-5" aria-hidden="true" fill="white" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <GoogleLogin responseGoogle={loginWithGoogle}/>
      </div>
    </div>
  )
}
