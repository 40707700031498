import { useRef, useState } from "react"

const ImageUploadField = ({
  setImageFile,
  isSubmitting,
  maxFileSizeInMb = 2,
  labelClassName = null,
  hidden = false
}: any) => {
  const [error, setError] = useState('')

  const fileInputRef = useRef<any>()

  const handleImageChange = (e: any) => {
    if (e.target.files.length < 1) {
      setError('')
      return
    }
    if (e.target.files[0].size < maxFileSizeInMb * 1024 * 1024) {
      setImageFile(e.target.files[0])
      setError('')
    } else {
      setError(`Max file size is ${maxFileSizeInMb} sMb`)
    }
  }

  const clickFileInput = (e: any) => {
    fileInputRef.current.click()
    // e.preventDefault()
  }

  return (
    <div className="grid grid-cols-1 ">
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg flex justify-center p-6 border-2 border-gray-300 border-dashed rounded-md"
          onClick={(e) => clickFileInput(e)}
        >
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-8 w-8 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              {
                hidden ?
                  <>
                    <span
                      className={labelClassName ? labelClassName : "relative cursor-pointer bg-white bg-opacity-60 rounded-md text-sm font-medium text-teal-600 hover:text-teal-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500 p-1"}
                    >{isSubmitting ? 'Uploading...' : 'Select a file'}</span>
                    <input
                      type="file"
                      hidden={hidden}
                      ref={fileInputRef}
                      id="file-upload"
                      name="file-upload"
                      className="hidden"
                      disabled={isSubmitting}
                      accept="image/png, image/jpeg" onChange={handleImageChange} required />
                  </>
                  :
                  <>
                    <label
                      htmlFor="file-upload"
                      hidden={hidden}
                      className={labelClassName ? labelClassName : "relative cursor-pointer bg-white bg-opacity-60 rounded-md text-sm font-medium text-teal-600 hover:text-teal-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500 p-1"}
                    >
                      <span>{isSubmitting ? 'Uploading...' : 'Select a file'}</span>
                      <input
                        type="file"
                        hidden={hidden}
                        ref={fileInputRef}
                        id="file-upload"
                        name="file-upload"
                        className="sr-only"
                        disabled={isSubmitting}
                        accept="image/png, image/jpeg" onChange={handleImageChange} required />
                    </label>
                  </>
              }
            </div>
            <div className="text-red-500">
              {error}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ImageUploadField
