import React, { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { accountService, commonHelpers } from '../services'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { commonTables } from '../recoil/atoms/common'
import { userState } from '../recoil/atoms/auth'

import { LoadingInside, NoMenuLayout } from '../components/layout'
import Header from '../components/onboarding/Header'
import OnboardingForm from '../components/onboarding/OnboardingForm'
import { onboardingJustCompletedAtom } from '@/recoil/atoms/app'

export interface StepInfo {
  form: string,
  title?: string,
  description?: string,
  validation?: Yup.AnyObjectSchema
  last?: boolean
}

const MFA_STEP: StepInfo = {
  form: 'mfa',
  title: 'Multi-Factor Authentication',
  description: 'Optionally setup multi-factor authentication via email or text for better account security.',
  last: true
}

const STEPS_INFO: StepInfo[][] = [
  [ // User type fork
    { // 0
      form: 'user_role',
      title: 'Which one describes you best?',
      description: 'This helps you to customize your experience.'
    }
  ],
  [ // Job seeker
    { // 1.0
      form: 'profile',
      title: 'Profile',
      description: 'This information will be displayed publicly so be careful what you share.',
      validation: Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required')
      })
    },
    { // 1.1
      form: 'work_experience',
      title: 'Work Experience',
      description: 'Please share your work experience.'
    },
    MFA_STEP // 1.2
  ],
  [ // Recruiter
    { // 2.0
      form: 'profile',
      title: 'Profile',
      description: 'This information will be displayed publicly so be careful what you share.',
      validation: Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required')
      })
    },
    { // 2.1
      form: 'recruiter_info',
      title: 'Recruiter Info',
      description: 'You can make money by recruiting, tell us about your recruiter experience.',
      validation: Yup.object().shape({
        recruiter_type: Yup.string().required('Recruiter type is required'),
        recruiter_tac: Yup.boolean().oneOf([true], 'Accepting the Terms & Conditions is required').required()
      })
    },
    MFA_STEP // 2.2
  ],
  [ // Both
    { // 3.0
      form: 'profile',
      title: 'Profile',
      description: 'This information will be displayed publicly so be careful what you share.',
      validation: Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required')
      })
    },
    { // 3.1
      form: 'work_experience',
      title: 'Work Experience',
      description: 'Please share your work experience.'
    },
    { // 3.2
      form: 'recruiter_info',
      title: 'Recruiter Info',
      description: 'You can make money by recruiting, tell us about your recruiter experience.',
      validation: Yup.object().shape({
        recruiter_type: Yup.string().required('Recruiter type is required'),
        recruiter_tac: Yup.boolean().oneOf([true], 'Accept Terms & Conditions is required').required()
      })
    },
    MFA_STEP // 3.3
  ],
  [ // Corporate job poster
    { // 4.0
      form: 'profile_company',
      title: 'Profile',
      description: 'This information will be displayed publicly so be careful what you share.',
      validation: Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        company_name: Yup.string().required('Company name is required').test('duplicateName', 'A company with this name already exists', async (val) => {
          if (!val) {
            return false
          }
          const res = await accountService.queryCompanyName(val ?? '')
          return !res.data.exists
        }),
        company_size: Yup.string().required('Company size is required')
      })
    },
    { // 4.1
      form: 'finish_contact'
    },
    MFA_STEP // 4.3
  ]
]

function Onboarding({ location }: any) {
  const common = useRecoilValue<any>(commonTables)
  const setUserData = useSetRecoilState<any>(userState)
  const [step, setStep] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const [initialValues, setInitialValues] = React.useState<any>()
  const [stepInfo, setStepInfo] = React.useState<StepInfo>(STEPS_INFO[0][0])
  const history = useHistory()
  const setOnboardingJustCompleted = useSetRecoilState<boolean>(onboardingJustCompletedAtom)
  const redirectAfterOnboardingTo = '/home'

  const defaultCountryID = useCallback(() => {
    return commonHelpers.defaultCountryID(common);
  }, [common]);

  const defaultCompensationType = useCallback(() => {
    return commonHelpers.defaultCompensationType(common);
  }, [common]);

  useEffect(() => {
    accountService.getOnboardingProgress().then((result) => {
      setStep(result.data.step)
      setInitialValues({
        role: result.data.role ?? '0',
        // profile
        first_name: result.data.first_name ?? '',
        last_name: result.data.last_name ?? '',
        country: result.data.country ?? defaultCountryID(),
        city: result.data.city ?? '',
        state: result.data.state ?? '',
        phone: result.data.phone ?? '',
        // company profile
        company_name: result.data.company_name ?? '',
        company_size: result.data.company_size ?? '',
        // work experience
        work_industry: result.data.work_industry ? commonHelpers.industryKeyFromId(common, result.data.work_industry) : '',
        work_role: result.data.work_role ? parseInt(result.data.work_role) : '',
        work_experience: result.data.work_experience ?? '',
        work_availability: result.data.work_availability ?? '',
        work_travel_availability: result.data.work_travel_availability ?? '',
        work_compensation_expectation: result.data.work_compensation_expectation ?? '',
        work_compensation_type: result.data.work_compensation_type ?? defaultCompensationType(),
        work_reference_link: result.data.work_reference_link ?? '',
        work_reference_link_type: result.data.work_reference_link_type ?? '',
        work_type: result.data.work_type ?? [],
        work_environment: result.data.work_environment ?? '',
        // recruiter info
        recruiter_type: result.data.recruiter_type ?? '',
        recruiter_tac: result.data.recruiter_tac ?? false
      })
      setLoading(false)
    }).catch(() => {
      history.replace('/home')
    })
  }, [defaultCountryID, defaultCompensationType, common, history])

  useEffect(() => {
    const [stepPrimary, stepSecondary] = step.toString().split('.')
    setStepInfo(STEPS_INFO[parseInt(stepPrimary)][parseInt(stepSecondary ?? '0')])
  }, [step])

  const sendData = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.completeOnboarding().then((result) => {
      accountService.profile().then((res) => {
        setUserData(res.data)
        setSubmitting(false)
        toast.success('Welcome to Gumption!')
        history.replace(redirectAfterOnboardingTo)
        setOnboardingJustCompleted(true)
      }).catch(() => {
        toast.error('Failed to fetch user data. Reloading page...')
        window.location.reload()
      })
    }).catch(() => {
      setStep(0)
      toast.error('Failed to complete onboarding. Please try again')
      setSubmitting(false)
    })
  }

  return (
    <NoMenuLayout title='Welcome to Gumption' auth={true}>
      <Header title={stepInfo.title ?? ''} info={stepInfo.description ?? ''} />
      <div className="pb-8 bg-white px-4 shadow sm:rounded-lg sm:px-10">
        {loading || !initialValues ? <LoadingInside /> : <OnboardingForm
          handleSubmit={sendData}
          common={common}
          step={step}
          setStep={setStep}
          stepInfo={stepInfo}
          initialValues={initialValues}
        />}
      </div>
    </NoMenuLayout>
  )
}

export default Onboarding