import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'

import { Layout } from '@/components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import { IRecruitingProcess } from '@/recoil/types'
import { recruitmentService } from '@/services'
import { toast } from 'react-toastify';
import RecruiterProcessManageView from '@/components/recruit/RecruiterProcessManageView'
import { Switch } from '@headlessui/react'
import { classNames } from '@/lib/helpers'
import RecruiterProcessCandidatesView from '@/components/recruit/RecruiterProcessCandidatesView'

const RecruiterProcess = () => {
  let { process_id } = useParams<{ process_id: string }>()

  const [process, setProcess] = useState<IRecruitingProcess>()
  const [loading, setLoading] = useState(true)
  const [editModeEnabled, setEditModeEnabled] = useState(false)

  const history = useHistory()
  const location = useLocation<any>()

  const hasBack = (location?.state?.from !== undefined)

  const goBack = () => {
    history.goBack()
  }

  const getProcessDetails = useCallback((editOverride = true) => {
    setLoading(true)
    recruitmentService.getRecruiterProcessDetails(parseInt(process_id), null).then((res) => {
      setProcess(res.data)
      editOverride && setEditModeEnabled(!res.data.linked_job)
      setLoading(false)
    }).catch(() => {
      toast.error("Failed to fetch details")
    })
  }, [process_id])

  useEffect(() => {
    getProcessDetails()
  }, [getProcessDetails])

  return (
    <Layout title="Job Pipeline" auth={true} back={true} sidebar={false}>
      {!loading ?
        <>
          {hasBack && <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
            <button
              onClick={goBack}
              className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
            >
              <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
              <span>Back</span>
            </button>
          </nav>}

          <div className="mb-2">
            <HeadingsH2 heading={`Recruiting Pipeline: "${process?.name}"`} className="truncate" />
          </div>

          {!!process?.linked_job && <div className="mb-4 pb-4 border-b border-b-gray-200">
            <Switch.Group as="div" className="p-4 flex items-center justify-between sm:w-96 bg-white shadow">
              <div className="flex flex-col">
                <Switch.Label as="p" className="text-sm font-medium text-gray-700" passive>
                  Edit Mode
                </Switch.Label>
                <Switch.Description className="text-sm text-gray-500">
                  Toggles the view between managing steps and managing candidates
                </Switch.Description>
              </div>
              <Switch
                checked={editModeEnabled}
                onChange={(enabled: boolean) => setEditModeEnabled(enabled)}
                className={classNames(
                  editModeEnabled ? 'bg-teal-500' : 'bg-gray-200',
                  'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    editModeEnabled ? 'translate-x-5' : 'translate-x-0',
                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>}

          {(process && editModeEnabled) && <RecruiterProcessManageView process={process} refetchProcess={() => getProcessDetails(false)} />}
          {(process && !editModeEnabled) && <RecruiterProcessCandidatesView process={process} refetchProcess={() => getProcessDetails(false)} />}
        </>
        :
        <LoadingInside />
      }
    </Layout>
  )
}

export default RecruiterProcess
