import { Formik } from 'formik'
import * as Yup from 'yup'
import { useRecoilValue } from 'recoil'
import { commonTables } from '@/recoil/atoms/common'
import BlueButton from '@/components/buttons/BlueButton'
import { TextInputField, DateTimeField, SelectField } from '@/components/forms/fields'

// any validation
const NoteSchema = Yup.object().shape({
  text: Yup.string()
    .required('Required'),
  type: Yup.string()
    .required('Required'),
})

const NoteFields = ({ values, errors, touched, handleChange, handleBlur, common, setFieldValue }: any) => {
  const commonFieldProps = { values, errors, touched, handleBlur, handleChange, setFieldValue }
  
  return (
    <div className="space-y-8 sm:space-y-5">
      <TextInputField
        fieldName="text"
        label="Note Text"
        required={true}
        {...commonFieldProps}
      />
      <DateTimeField
        fieldName="datetime"
        label="Start Datetime"
        initialDate={values.datetime}
        required={true}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="type"
        label="Note Type"
        options={[...common.note_types]?.sort((a: any, b: any) => a.id.localeCompare(b.id))}
        required={true}
        {...commonFieldProps}
      />
    </div>
  )
}

const NoteForm = ({ handleSubmit, object, formClasses = '', initial=null}: any) => {
  const common = useRecoilValue<any>(commonTables)

  return (
    <Formik
      initialValues={{
        text: initial?.text || '',
        datetime: initial?.datetime || new Date().toISOString(),
        type: initial?.type || ''
      }}
      validationSchema={NoteSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={formClasses}>
          <NoteFields
            {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common, setFieldValue }}
          />
          <BlueButton
            disabled={!isValid || !dirty || isSubmitting}
            type="submit"
            title="Save"
            className="mt-5 w-full"
          />
        </form>
      )}
    </Formik>
  )
}

export default NoteForm
