import { Formik } from 'formik'
import * as Yup from 'yup'
import { useRecoilValue } from 'recoil'
import { commonTables } from '../../../../recoil/atoms/common'
import { commonHelpers } from '../../../../services/common.service'
import BlueButton from '../../../buttons/BlueButton'
import { TextInputField, SelectField, NumberInputField } from '../../../forms/fields'

import { CityField } from '../../person/forms/JobPreferencesForm'
import { Switch } from '@headlessui/react'
import { classNames } from '@/lib/helpers'
import Confirm from '@/components/modals/Confirm'
import { useEffect, useRef, useState } from 'react'
import { AccountRoles, recruitmentService } from '@/services'
import { userState } from '@/recoil/atoms/auth'
import { IRecruitingProcess } from '@/recoil/types'
import { toast } from 'react-toastify'

// any validation
const JobInfoSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  description: Yup.string()
    .required('Required'),
  recruiting_process: Yup.string()
    .required('Required'),
  compensation_lower: Yup.number().positive('Must be a positive number'),
  compensation_upper: Yup.number().positive('Must be a positive number'),
  positions_available: Yup.number()
    .required('Required')
    .integer('Must be a whole number')
    .moreThan(0, 'Must be greater than zero')
})

const JobFields = ({ values, errors, touched, handleChange, handleBlur, common }: any) => {
  const userData = useRecoilValue(userState)
  const commonFieldProps = { values, errors, touched, handleBlur, handleChange }

  const [recruitingProcesses, setRecruitingProcesses] = useState([])

  useEffect(() => {
    recruitmentService.getAbstractProcesses(null).then((res) => {
      setRecruitingProcesses(res.data.map((process: IRecruitingProcess) => ({ id: process.id, title: process.name })))
    }).catch(() => {
      toast.error("Failed to fetch recruiting pipeline options")
    })
  }, [])

  return (
    <div className="space-y-8 sm:space-y-5">
      <TextInputField
        fieldName="title"
        label="Job Title"
        required={true}
        {...commonFieldProps}
      />
      <TextInputField
        fieldName="description"
        label="Job Description"
        required={true}
        {...commonFieldProps}
      />
      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="requirements" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Job Responsibilities
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="requirements"
              name="requirements"
              autoComplete="requirements"
              className="max-w-lg block w-full shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              onChange={handleChange} onBlur={handleBlur} value={values.requirements}
            />
          </div>
          {errors.requirements && touched.requirements && errors.requirements}
        </div>
      </div>
      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="qualification" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Job Qualification
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="qualification"
              name="qualification"
              autoComplete="qualification"
              className="max-w-lg block w-full shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              onChange={handleChange} onBlur={handleBlur} value={values.qualification}
            />
          </div>
          {errors.qualification && touched.qualification && errors.qualification}
        </div>
      </div>
      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="benefits" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Job Benefits
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="benefits"
              name="benefits"
              autoComplete="benefits"
              className="max-w-lg block w-full shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              onChange={handleChange} onBlur={handleBlur} value={values.benefits}
            />
          </div>
          {errors.benefits && touched.benefits && errors.benefits}
        </div>
      </div>
      <NumberInputField
        fieldName="compensation_lower"
        label="Job Compensation (Lower)"
        {...commonFieldProps}
      />
      <NumberInputField
        fieldName="compensation_upper"
        label="Job Compensation (Upper)"
        {...commonFieldProps}
      />
      <SelectField
        fieldName="compensation_type"
        label="Job Compensation Type"
        options={common?.compensation_types}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="industry"
        label="Industry"
        options={commonHelpers.industryDictionaryToArray(common?.industries)}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="role"
        label="Role"
        disabled={!values.industry}
        options={commonHelpers.rolesForIndustryByKey(common?.industries, values.industry)}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="state"
        label="State"
        options={common?.states}
        {...commonFieldProps}
      />
      <CityField
        {...{ values, errors, touched, handleChange, handleBlur }}
      />
      <SelectField
        fieldName="work_type"
        label="Work Type"
        options={common?.work_types}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="work_env"
        label="Work Environment"
        options={common?.work_environments}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="experience"
        label="Experience Level"
        options={common?.experience_levels}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="availability"
        label="Availability"
        options={common?.availabilities}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="travel_availability"
        label="Availability to Travel"
        options={common?.travel_availabilities}
        {...commonFieldProps}
      />
      <NumberInputField
        fieldName="positions_available"
        label="Positions Available"
        required={true}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="recruiting_process"
        label="Recruiting Pipeline"
        options={recruitingProcesses}
        required={true}
        {...commonFieldProps}
      />
      {(userData?.role === AccountRoles.COMPANY && values['status']) && <Switch.Group as="li" className="py-4 flex items-center justify-between">
        <div className="flex flex-col">
          <Switch.Label as="p" className="text-sm font-medium text-gray-700" passive>
            Publish Job?
          </Switch.Label>
          <Switch.Description className="text-sm text-gray-500">
            Enable to auto-publish this job upon creation.
          </Switch.Description>
        </div>
        <Switch
          checked={values['status'] === 'Published'}
          onChange={(enabled: boolean) => handleChange({ target: { name: 'status', value: enabled ? 'Published' : 'Draft' } })}
          className={classNames(
            values['status'] === 'Published' ? 'bg-teal-500' : 'bg-gray-200',
            'sm:mr-20	ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              values['status'] === 'Published' ? 'translate-x-5' : 'translate-x-0',
              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </Switch.Group>}
    </div>
  )
}

const JobForm = ({ handleSubmit, object, formClasses = '', initial=null}: any) => {
  const common = useRecoilValue<any>(commonTables)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const handleSubmitRef = useRef<(e?: React.FormEvent<HTMLFormElement>) => void>()

  const handleSubmitWrapper = (values: any, doSubmit: (e?: React.FormEvent<HTMLFormElement>) => void, e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    
    if (values['status'] === 'Published') {
      handleSubmitRef.current = doSubmit
      setIsConfirmOpen(true)
    } else {
      doSubmit(e)
    }
  }

  return (<>
    <Confirm
      title="Are you sure you want to auto-publish this job after saving it?"
      confirm_text="Save & Publish"
      info_text="Auto-publishing a job will notify en masse all users whose preferences match this job."
      onConfirm={() => handleSubmitRef.current && handleSubmitRef.current()}
      isOpen={isConfirmOpen}
      setIsOpen={setIsConfirmOpen}
    />
    <Formik
      initialValues={{
        // personal
        title: initial?.title || '',
        description: initial?.description || '',
        requirements: initial?.requirements || '',
        benefits: initial?.benefits || '',
        qualification: initial?.qualification || '',
        company: initial?.company.id || object.id,
        country: initial?.country?.id || commonHelpers.defaultCountryID(common),
        state: initial?.state?.id || (object.state && object.state.id) || '',
        city: initial?.city?.id || (object.city && object.city.id) || '',
        industry: initial?.industry?.slug || (object.industry && object.industry.slug) || '',
        role: initial?.role?.id || '',
        work_type: initial?.work_type?.id || '',
        work_env: initial?.work_env?.id || '',
        experience: initial?.experience?.id || '',
        availability: initial?.availability?.id || '',
        travel_availability: initial?.travel_availability?.id || '',
        compensation_lower: initial?.compensation_lower || '',
        compensation_upper: initial?.compensation_upper || '',
        compensation_type: initial?.compensation_type?.id || '',
        positions_available: initial?.positions_available ?? 1,
        recruiting_process: initial?.recruiting_process || '',
        status: !initial ? 'Draft' : undefined
      }}
      validationSchema={JobInfoSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={(e) => handleSubmitWrapper(values, handleSubmit.bind(this), e)} className={formClasses}>
          <JobFields
            {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common }}
          />
          <BlueButton
            disabled={!isValid || !dirty || isSubmitting}
            type="submit"
            title="Save"
            className="mt-5 w-full"
          />
        </form>
      )}
    </Formik>
  </>)
}

export default JobForm
