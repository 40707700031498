import React, { useEffect, useState } from 'react'
import { NoMenuLayout } from '@/components/layout'
import LoginForm from '@/components/auth/LoginForm'
import SocialAuth from '@/components/auth/SocialAuth'
import { accountService } from '@/services'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Header from '@/components/auth/Header'

// State imports
import { useRecoilState } from 'recoil'
import { isLoggedIn } from '@/recoil/atoms/auth'

// Loading
import Loading from '@/components/layout/top_level/Loading'
import Modal from '@/components/modals/Modal'
import MFACodeForm from '@/components/auth/MFACodeForm'

export default function Login({ location }: any) {
  const [loggedIn, setLoggedIn] = useRecoilState(isLoggedIn)
  const [loading, setLoading] = useState(true)
  const [isMFADialogOpen, setIsMFADialogOpen] = useState(false)
  const [mfaEphemeralToken, setMfaEphemeralToken] = useState<string>()
  let history = useHistory()


  const redirectAfterLoginTo = '/home' //location.state?.from?.pathname || '/'

  useEffect(() => {
    if (loggedIn) {
      setLoading(false)
      history.push(redirectAfterLoginTo)
    } else {
      setLoading(false)
    }
  }, [loggedIn, history, redirectAfterLoginTo])

  const handleLoginSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.login(values).then(res => {
      if (res.success) {
        setSubmitting(false)

        if (res.data.ephemeral_token) {
          const friendlyMethod = {
            'email': 'email',
            'app': 'authenticator app',
            'yubi': 'YubiKey',
            'sms_api': 'phone number',
            'sms_twilio': 'phone number',
            'device': 'device'
          }[res.data.method ?? 'device']
          toast.info(`An MFA code has been sent to your ${friendlyMethod}`)
          setMfaEphemeralToken(res.data.ephemeral_token)
          setIsMFADialogOpen(true)
        } else {
          toast.success(`Logged in...`)
          setLoggedIn(true)
          history.push(redirectAfterLoginTo)
        }
      }
    }).catch(err => {
      toast.error(`${err.message}`)
      setSubmitting(false)
    })
  }

  const handleMFACodeSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.loginCode(mfaEphemeralToken ?? '', values.code).then(res => {
      if (res.success) {
        toast.success(`Logged in...`)
        setIsMFADialogOpen(false)
        setLoggedIn(true)
        setSubmitting(false)
        history.push(redirectAfterLoginTo)
      }
    }).catch(err => {
      toast.error('Invalid or expired MFA code')
      setIsMFADialogOpen(false)
      setSubmitting(false)
    })
  }

  return (
    <>
      <Modal
        title="Multi-Factor Authentication"
        open={isMFADialogOpen}
        setOpen={setIsMFADialogOpen}
        hasForm={true}
      >
        <MFACodeForm handleSubmit={handleMFACodeSubmit} />
      </Modal>
      {loading ? <Loading /> : (<>
        <NoMenuLayout title="Login">
          <div className="bg-gray-50 flex flex-col justify-center">
            <Header title='Join our revolution' info='The easiest way to connect talented people and new opportunities.' />
            <div className="sm:mx-auto sm:w-full sm:max-w-md sm:pb-6">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <LoginForm handleSubmit={handleLoginSubmit} />
                <p className="mt-2 text-center text-sm text-gray-600 max-w">
                  Don't have an account?{' '}
                  <Link 
                    to={{ pathname: '/register/', state: { from: redirectAfterLoginTo } }} 
                    className="font-medium text-teal-600 hover:text-teal-500">
                    Sign Up
                  </Link>
                </p>
                <SocialAuth redirectToAfterLogin={redirectAfterLoginTo} />
              </div>
            </div>
          </div>
        </NoMenuLayout>
      </>)}
    </>
  )
}
